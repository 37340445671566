/*** 
=============================================
    offering Area2 Style      
=============================================
***/
.offering-area2 {
  background-color: #f5f5f5;
  position: relative;
  display: block;
  padding: 120px 0 90px;
  /* border-bottom: 2px solid #f8f6f4; */
  z-index: 1;
}
.single-offering-box2 {
  position: relative;
  display: block;
  padding: 40px 0 34px;
  text-align: center;
  background: #fff;
  border-radius: 7px;
  margin-bottom: 30px;
  z-index: 1;
}

.single-offering-box2 p {
  position: relative;
  display: block;
  padding: 40px 34px;
  text-align: left;
  margin: 10dp;
  background: #fff;
  border-radius: 7px;
  margin-bottom: 30px;
  z-index: 1;
}

.single-offering-box2 span:before {
  color: #48bdb4;
  font-size: 60px;
  line-height: 60px;
  font-weight: 400;
  transition: all 0.5s ease-in-out 0.5s;
}

.single-offering-box2 h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 26px 0 0;
  transition: all 0.5s ease-in-out 0.7s;
}
